import s from "../Styles/Pages/home.module.css";
import {
  getAnnouncements,
  getUsefulInfo,
  getProgrammesAndContests,
} from "../api/contentTypes";
import { useState, useEffect } from "react";
import { Loader } from "../Components/Loader";
import { AnnouncementCard } from "../Components/AnnouncementCard";
import { SideBarContentTypeCard } from "../Components/SideBarContentTypeCard";
import Divider from "../Components/Divider";
import { Link } from "react-router-dom";

export function Home(props) {
  const [announcements, setAnnouncements] = useState({});
  const [usefulInfo, setUsefulInfo] = useState({});
  const [programmesAndContests, setProgrammesAndContests] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
      await getAnnouncements(2).then((r) => {
        if (r.status === 200) {
          setAnnouncements(r.data.data);
        }
        else{
          console.log(r.data);
        }
      });
      await getUsefulInfo().then((r) => {
        if (r.status === 200) {
          setUsefulInfo(r.data.data);
          console.log(r.data.data);
        }
        else{
          console.log(r.data);
        }
      });
      await getProgrammesAndContests().then((r) => {
        if (r.status === 200) {
          setProgrammesAndContests(r.data.data);
          console.log(r.data.data);
        }
        else{
          console.log(r.data);
        }
      });
      setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  document.title = props.title;
  return (
    <div className="pageMain">
      <div className="pageContent">
        <div className={s.leftSideBar + " leftSideBar"}>
          <section className={s.usefulInfoSection}>
            <h2 className={s.usefulInfoTitle}>Useful Info</h2>
            {usefulInfo.map((item) => (
              <SideBarContentTypeCard key={item.id} data={item}/>
            ))}
          </section>
        </div>
        <div className={s.mainContent + " mainContent"}>
          <section className={s.announcementSection}>
            <Divider
              width="100%"
              spacing="10px"
              height="2px"
              color="var(--theme-color-800)"
              flexLeft="1"
              flexRight="1"
            >
              <h2 className={s.announcementTitle}>Latest Announcements</h2>
            </Divider>
            <div className={s.announcements}>
              {announcements.map((item) => (
                <AnnouncementCard key={item.id} data={item} />
              ))}
            </div>
            <div className={s.readAll}>
              <Link className={s.readAllButton} to={"/announcements"}>
                Read All Announcements
              </Link>
            </div>
          </section>
          {/* <section className={s.announcementDiv}>
            <Divider
              width="100%"
              spacing="10px"
              height="2px"
              color="var(--theme-color-800)"
              flexLeft="1"
              flexRight="1"
            >
              <h2 className={s.announcementTitle}>Latest Announcements</h2>
            </Divider>
            <div className={s.announcements}>
              {announcements.map((announcement) => (
                <AnnouncementCard key={announcement.id} data={announcement} />
              ))}
            </div>
          </section> */}
        </div>
        <div className={s.rightSideBar + " rightSideBar"}>
          <section className={s.programmesAndContestsSection}>
            <h2 className={s.programmesAndContestsTitle}>Programmes And Contests</h2>
            {programmesAndContests.map((item) => (
              <SideBarContentTypeCard key={item.id} data={item} />
            ))}
          </section>
        </div>
      </div>
    </div>
  );
}
