import { Link } from 'react-router-dom';
import s from '../Styles/Components/header.module.css'
import { useState, useEffect } from "react";

export function Header(props){
    const [isSticky, setSticky] = useState(false);
    const stickyEffectTakePlace = 20;

    const checkScrollTop = () => {
        if (window.pageYOffset > stickyEffectTakePlace){
            setSticky(true);
        } else if (window.pageYOffset <= stickyEffectTakePlace){
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <div className={isSticky  ? (s.headerWrap +" "+ s.isSticky) : s.headerWrap}>
        <div className={s.leftCnt}>
            <Link to='/'> 
                <img className={s.headerLogo} alt='logo' src={"http://apps.immko.gr:1337"+props.schoolInfo.SchoolLogo.data.attributes.url} />
            </Link>
        </div>
        <div className={s.rightCnt}>
            <nav className={s.menu}>
                {props.menu.map((menuitem)=>
                    <Link key={menuitem.id} className={s.menuItem+" menu-item-"+menuitem.id} to={menuitem.path}> 
                        <span className={s.menuItemIcon}>{menuitem.icon}</span>
                        <span className={s.menuItemTitle}>{menuitem.title}</span>
                    </Link>
                )}
            </nav>
        </div>
      </div>  
    )
}

