import s from "../Styles/Components/announcementCard.module.css";
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'

export function AnnouncementCard(props) {
  return (
    <div className={s.card}>
      {props.data.attributes.Featureimage.data ? (
        <div className={s.imageDiv}>
          <Link to={"/content-type/" + props.data.id}>
            <img className={s.imageUrl} alt="logo" src={"http://apps.immko.gr:1337"+props.data.attributes.Featureimage.data.attributes.url} />
          </Link>
        </div>
      ) : null}
      <div className={s.info}>
        <Link to={"/content-type/" + props.data.id}>
          <h3 className={s.title}>{props.data.attributes.Title}</h3>
          <div className={s.date}>{props.data.attributes.date}</div>
        </Link>
        <div className={s.excerpt}><Markdown>{props.data.attributes.Excerpt}</Markdown></div>
      </div>
      <div className={s.footer}>
        <p className={s.categories}>{props.data.attributes.ContentType}</p>
        <Link to={"/content-type/" + props.data.id} className={s.action}>
          Read More
        </Link>
      </div>
    </div>
  );
}
