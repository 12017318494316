import { Link } from "react-router-dom";
import s from "../Styles/Components/footer.module.css";

export function Footer(props) {
  const currentYear = new Date().getFullYear();

  return (
    <div className={s.footerWrap}>
      <h1>{props.title}</h1>
      <div className={s.immkoDiv}>
        <p className={s.footerCopyrights}>
          Copyright {currentYear} - All rights Reserved - Design & Develop by <Link to={"https://immko.gr/"}>IMMKO</Link>
        </p>
      </div>
    </div>
  );
}
