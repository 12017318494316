import { ReactComponent as AboutUsIcon } from "../assets/svg/calling-flatline.svg";
import s from '../Styles/Pages/contact.module.css'
import Divider from "../Components/Divider";
import Markdown from 'react-markdown'

export function ContactUs(props) {
  document.title = props.title;
  return (
    <div className="pageMain">
      <div className="pageContent">
        <div className={s.mainContent}>
          <Divider
            width="100%"
            spacing="10px"
            height="1px"
            color="var(--theme-color-50)"
            widthLeft="20px"
            flexRight="1"
          >
            <h2 className={s.subTitlePage}>{props.title}</h2>
          </Divider>
          <div className={s.contactInfoSubContent}>
            <span>
              Address:{" "}
              <a href={props.schoolInfo.AddressLink}>
                {props.schoolInfo.Address}
              </a>
            </span>
            <span>
              Principal Phone:{" "}
              <a href={"tel:" + props.schoolInfo.PrincipalPhone}>
                {props.schoolInfo.PrincipalPhone}
              </a>
            </span>
            <span>
              Principal Phone 2:{" "}
              <a href={"tel:" + props.schoolInfo.PrincipalPhone2}>
                {props.schoolInfo.PrincipalPhone2}
              </a>
            </span>
            <span>
              e-mail:{" "}
              <a href={"mailto:" + props.schoolInfo.email}>
                {props.schoolInfo.email}
              </a>
            </span>
          </div>
          <Divider
            width="100%"
            spacing="10px"
            height="1px"
            color="var(--theme-color-50)"
            widthLeft="20px"
            flexRight="1"
          >
            <h2 className={s.subTitlePage}>Ώρες λειτουργίας</h2>
          </Divider>
          <div className={s.contactInfoSubContent}>
              <Markdown>{props.schoolInfo.HoursOfOperation}</Markdown>
          </div>
        </div>
        <div className={s.rightSideBar}>
          <div className={s.circleDiv}>
            <AboutUsIcon className={s.contactUsIcon} />
          </div>
        </div>
      </div>
    </div>
  );
}
