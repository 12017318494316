export default function Divider(props) {
  return (
    <div
      style={{
        display: "flex",
        width:props.width,
        padding: props.spacing + " 0px",
        alignItems: "center",
      }}
    >
      <hr
        style={{
          width: props.widthLeft,
          flex: props.flexLeft,
          height: props.height,
          backgroundColor: props.color,
          borderWidth: 0,
        }}
      />
      {props.children}
      <hr
        style={{
            width: props.widthRight,
          flex: props.flexRight,
          height: props.height,
          backgroundColor: props.color,
          borderWidth: 0,
        }}
      />
    </div>
  );
}
