import { Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Announcements } from "./Pages/Announcements";
import { ContactUs } from "./Pages/ContactUs";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer"
import { getMainInfo } from "../src/api/mainInfo";
import { useState, useEffect } from "react";
import { Loader } from "./Components/Loader";
import { GoHome,GoBell,GoMail} from "react-icons/go";
import {SingleContentType} from "./Pages/SingleContentType"
import { getContentTypeById,} from "./api/contentTypes";

function App() {
  const [schoolInfo, setSchoolInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const menu = [
    {
      id: 1,
      title:"Αρχική",
      path: '/',
      element: <Home title="Αρχική"/>,
      icon:<GoHome size={24} />,
    },
    {
      id: 2,
      title:"Announcements",
      path: '/announcements',
      element: <Announcements title="Announcements"/>,
      icon:<GoBell size={24} />,
    },
    {
      id: 3,
      title:"Contact Us",
      path: '/contact-us',
      element: <ContactUs title="Contact Us" schoolInfo={schoolInfo}/>,
      icon:<GoMail size={24} />,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
      await getMainInfo().then((r) => {
        if (r.status === 200) {
          setSchoolInfo(r.data.data.attributes);
        }
        else{
          console.log(r.data);
        }
        setIsLoading(false);
      });
  };

  if (isLoading){
    return(<Loader/>)
  }

  return (
    <div className="app">
      <Header menu={menu} schoolInfo={schoolInfo} />
      <Routes>
        {menu.map((menuitem) => 
          <Route key={menuitem.id} path={menuitem.path} element={menuitem.element} />
        )}
          <Route path="/content-type/:id" element={<SingleContentType getData={getContentTypeById}/>} />
      </Routes>
      <Footer title="footer"/>
    </div>
  );
}

export default App;
