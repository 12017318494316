import axios from "axios";
import {getConfig} from "./apiConfig"

export const getMainInfo = async () => {
  return await axios({
    ...getConfig,
    url: `${getConfig.baseUrl}/api/info-3o-dimotiko-sxoleio?populate=*`,
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: error.code,
        data: error.message,
      };
    });
};