import s from "../Styles/Components/usefulInfoCard.module.css";
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'

export function SideBarContentTypeCard(props) {
    let contentUrl
    if(props.data.attributes.ExternelLink!==""&&props.data.attributes.ExternelLink!==null&&props.data.attributes.ExternelLink!==undefined){
        contentUrl=props.data.attributes.ExternelLink;
    }
    else{
        contentUrl="/content-type/" + props.data.id;
    }

  return (
    <div className={s.card}>
      {props.data.attributes.Featureimage.data ? (
        <div className={s.imageDiv}>
          <Link to={contentUrl}>
            <img className={s.imageUrl} alt="logo" src={"http://apps.immko.gr:1337"+props.data.attributes.Featureimage.data.attributes.url} />
          </Link>
        </div>
      ) : null}

      <div className={s.info}>
        <Link to={contentUrl}>
          <h3 className={s.title}>{props.data.attributes.Title}</h3>
        </Link>
        <div className={s.excerpt}><Markdown>{props.data.attributes.Excerpt}</Markdown></div>
      </div>
    </div>
  );
}
