import axios from "axios";
import {getConfig} from "./apiConfig"

export const getAnnouncements = async (limit) => {
  var url="posts-3o-dimotiko-sxoleio/";
  if (!limit || limit === 0 || limit === undefined) {
    url="posts-3o-dimotiko-sxoleio?&filters[$and][0][ContentType][$eq]=Announcements&populate=*"
  } else {
    url="posts-3o-dimotiko-sxoleio?pagination[limit]="+limit+"&filters[$and][0][ContentType][$eq]=Announcements&populate=*"
  }

  return await axios({
    ...getConfig,
    url: `${getConfig.baseUrl}/api/${url}`,
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: error.code,
        data: error.message,
      };
    });
};

export const getContentTypeById = async (id) => {
  return await axios({
    ...getConfig,
    url: `${getConfig.baseUrl}/api/posts-3o-dimotiko-sxoleio/${id}?populate=*`,
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: error.code,
        data: error.message,
      };
    });
};

export const getUsefulInfo = async (limit) => {
  var url="posts-3o-dimotiko-sxoleio/";
  if (!limit || limit === 0 || limit === undefined) {
    url="posts-3o-dimotiko-sxoleio?&filters[$and][0][ContentType][$eq]=Useful%20Info&populate=*"
  } else {
    url="posts-3o-dimotiko-sxoleio?pagination[limit]="+limit+"&filters[$and][0][ContentType][$eq]=Useful%20Info&populate=*"
  }

  return await axios({
    ...getConfig,
    url: `${getConfig.baseUrl}/api/${url}`,
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: error.code,
        data: error.message,
      };
    });
};

export const getProgrammesAndContests = async (limit) => {
  var url="posts-3o-dimotiko-sxoleio/";
  if (!limit || limit === 0 || limit === undefined) {
    url="posts-3o-dimotiko-sxoleio?&filters[$and][0][ContentType][$eq]=Programs%20And%20Contests&populate=*"
  } else {
    url="posts-3o-dimotiko-sxoleio?pagination[limit]="+limit+"&filters[$and][0][ContentType][$eq]=Programs%20And%20Contests&populate=*"
  }

  return await axios({
    ...getConfig,
    url: `${getConfig.baseUrl}/api/${url}`,
  })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: error.code,
        data: error.message,
      };
    });
};
