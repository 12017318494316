/* eslint-disable react-hooks/exhaustive-deps */
import s from "../Styles/Pages/singleContentTypeCard.module.css";
import { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Loader } from "../Components/Loader";
import { useParams } from "react-router-dom";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export function SingleContentType(props) {
  const { id } = useParams();
  document.title = "Loading...";
  const [contentType, setContentType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let shareUrl = window.location.href;

  //Οταν αποκτησει κανονικο domain φευγουν οι δυο επομενες σειρες
  const temporaryUrl = "https://immko.gr/";
  shareUrl = temporaryUrl;
  //-------------------------------------------------------------

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let r = await props.getData(id);
      setContentType(r.data.data.attributes);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  function formatDate(date){
    var newDate=date.split("-")[2].split("T")[0]+"/"+date.split("-")[1]+"/"+date.split("-")[0]
    return(newDate)
  }

  document.title = contentType.Title;

  return (
    <div className="pageMain">
      <div className="pageContent">
        <div className={s.mainContent + " mainContent"}>
          {contentType.Featureimage.data.attributes.url ? (
            <img className={s.imageUrl} alt="logo" src={"http://apps.immko.gr:1337"+contentType.Featureimage.data.attributes.url} />
          ) : null}
          <h1 className={s.title}>{contentType.Title}</h1>
          <span className={s.meta}>
            {formatDate(contentType.publishedAt)} - {contentType.ContentType}
          </span>
          <div className={s.content}>{parse(contentType.Content)}</div>
        </div>
        <div className={s.rightSideBar + " rightSideBar"}>
          <div className={s.socialCnt}>
            <span className={s.socialShareTitle}>Share this to:</span>
            <div className={s.socialShare}>
              <div className={s.socialItem}>
                <FacebookShareButton
                  url={shareUrl}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={"80%"} round />
                </FacebookShareButton>
              </div>
              <div className={s.socialItem}>
                <EmailShareButton
                  url={shareUrl}
                  subject={contentType.title}
                  body="body"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={"80%"} round />
                </EmailShareButton>
              </div>
              <div className={s.socialItem}>
                <ViberShareButton
                  url={shareUrl}
                  title={contentType.title}
                  className="Demo__some-network__share-button"
                >
                  <ViberIcon size={"80%"} round />
                </ViberShareButton>
              </div>
              <div className={s.socialItem}>
                <WhatsappShareButton
                  url={shareUrl}
                  title={contentType.title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={"80%"} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
