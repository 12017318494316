import s from '../Styles/Pages/announcements.module.css'
import { getAnnouncements } from "../api/contentTypes";
import { useState, useEffect } from "react";
import { Loader } from "../Components/Loader";
import {AnnouncementCard} from "../Components/AnnouncementCard"

export function Announcements(props) {
  document.title = props.title;
  const [announcements, setAnnouncements] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
      await getAnnouncements().then((r) => {
        if (r.status === 200) {
          setAnnouncements(r.data.data);
          console.log(r.data.data);
        }
        else{
          console.log(r.data);
        }
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="pageMain">
      <div className="pageContent">
        <div className={s.mainContent +" mainContent"}>
          {announcements.map((announcement)=>
            <AnnouncementCard key={announcement.id} data={announcement}/>
          )}
        </div>
        <div className={s.rightSideBar +" rightSideBar"}></div>
      </div>
    </div>
  );
}
